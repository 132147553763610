.word-annotation
{
    position: relative;
    background-position: 0 28px;
    background-repeat: repeat-x;
    cursor:default
    
}

.word-annotation:hover>*{ opacity:1; visibility:visible; }


@media screen and (max-width: 860px) {


    .word-annotation
    {
        background-position: 0 18px;
        position: static;
        
    }

    .word-annotation:hover { font-weight:500; }

}