.tooltip
{
    position: absolute;
    left:-8px;
    font-size: 16px;
    background-color: white;
    padding:16px 20px;
    line-height:24px;
    text-align: left;
    width:320px;
    border-radius: 8px;
    box-shadow: 0px 5px 12px rgba(0,0,0,0.3);
    opacity:0;
    transition-property:opacity;
    transition-duration:.25s;
    visibility:hidden;
    z-index:10;
}


@media screen and (max-width: 860px) {

    .tooltip {
        font-size:15px;
        font-weight: 400;
        left:0;
        right:0;
        margin: 0 auto;
    }

}