@import url('https://fonts.googleapis.com/css2?family=Grandstander:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400&display=swap');
* { padding:0; margin:0; }


:root {
    --color-perfect: #FFB8B8;

    --text-label: 
  }

#root { display:flex; align-content:space-between; justify-content:space-between; flex-direction:column; min-height:100vh}
#top-content { min-width:860px; padding:0 32px;}

body { font-family: 'Grandstander', sans-serif; }

#logo {font-size:24px; font-weight:bold; color:rgba(0,0,0,0.25); margin-top:32px; }
#logo span {background:none;}
#logo span b {transition-property:color; transition-duration:0.5s;}


h1, h2 { width:860px; margin:64px auto 16px; font-size:32px; font-weight:500; text-align:center;}

h2 { font-weight:400; font-size:17px; margin:24px auto 48px}

#filters-and-sentence { margin:0 auto}

#filters { width:100%; margin:0 auto; display:flex; align-items:stretch; }

#small-filters-container { width:calc(50% - 16px); display:flex; margin-right:16px; }

#sentence-switcher-container { width:50%}
#filters .switcher-container {float:left; margin-right:16px; }

    #filters .switcher-container:last-child { margin-right:0; }

    .switcher-container .label { color:rgba(0,0,0,0.55); margin-bottom:12px;  font-size:16px; }


    .switcher { 
        width: 160px;
        padding:6px;        
        border-radius: 8px;
        background:lightgray;
        flex-grow:1;
    }

    .green, .me-past { background:linear-gradient(130.77deg, #C4E67B 0%, #BCF2FE 100%);}
        .me-past.perfect { background:linear-gradient(130.77deg, #C4E67B 0%, #BCF2FE 50%, var(--color-perfect) 100%);}
        .green .option.active, .me-past .option.active { color:#0db846}
    .me-present { background:linear-gradient(130.77deg, #C4E67B 0%, #FFD99F 100%);}
        .me-present.perfect { background:linear-gradient(130.77deg, #C4E67B 0%, #FFD99F 50%, var(--color-perfect) 100%);}
        .me-present .option.active { color:#0db846}
    .me-future { background:linear-gradient(130.77deg, #C4E67B 0%, #FFBABA 100%);}
        .me-future.perfect { background:linear-gradient(130.77deg, #C4E67B 0%, #FFBABA 50%, var(--color-perfect) 100%);}
            .me-future .option.active { color: #0db846;}


    .her-past { background:linear-gradient(130.77deg, #FFA370 0%, #D1FFD5 100%); }
        .her-past.perfect { background:linear-gradient(130.77deg, #FFA370 0%, #D1FFD5 50%, var(--color-perfect) 100%); }
        .her-past .option.active { color: #ff7b1c;}
    .orange, .her-present { background:linear-gradient(130.77deg, #FFA370 0%, #FFFBA0 100%); }
        .her-present.perfect { background:linear-gradient(130.77deg, #FFA370 0%, #FFFBA0 50%, var(--color-perfect) 100%); }
        .orange .option.active, .her-present .option.active { color: #ff7b1c; }
    .her-future { background:linear-gradient(130.77deg, #FFA370 0%, #FEDBFF 100%); }
        .her-future.perfect { background:linear-gradient(130.77deg, #FFA370 0%, #FEDBFF 50%, var(--color-perfect) 100%); }
        .her-future .option.active { color:#d55842;}


    .them-past { background:linear-gradient(130.77deg, #DDA6EF 0%, #BCFEC3 100%);}
        .them-past.perfect { background:linear-gradient(130.77deg, #DDA6EF 0%, #BCFEC3 50%, var(--color-perfect) 100%);}
            .them-past .option.active { color: #5c16a7;}
    .them-present { background:linear-gradient(130.77deg, #DDA6EF 0%, #FFEC8A 100%);}
        .them-present.perfect { background:linear-gradient(130.77deg, #DDA6EF 0%, #FFEC8A 50%, var(--color-perfect) 100%);}
        .them-present .option.active { color:#b03cb2;} 
    .purple, .them-future { background:linear-gradient(130.77deg, #DCA4EF 0%, #FFD8C7 100%); }
        .them-future.perfect { background:linear-gradient(130.77deg, #DCA4EF 0%, #FFD8C7 50%, var(--color-perfect) 100%); }
        .purple .option.active, .them-future .option.active { color: #b03cb2; } 
        
      /*  .purple, .them-future { background:linear-gradient(130.77deg, #9EF4D5 0%, #FFD99F 100%); }
        .purple .option.active, .them-future .option.active { color: #703971; }*/

    


        .switcher .plashka { top:0px; transition-property:all; transition-duration:0.2s; opacity:0; position:relative; border-radius:4px; height:54px; margin-bottom:-54px; width:100%; background-color:white; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);}
            .switcher .plashka.visible { opacity:1 }
        

       .switcher .option { transition-property:all; transition-duration:0.3s; height:54px; display:flex; justify-content:center; align-items:center; font:normal 18px 'Grandstander', sans-serif; z-index:1; position:relative; cursor:pointer; margin-bottom:4px; text-align:center; border-radius:4px; }
            .option.active { color: #038830; }
            .option:last-child { margin-bottom: 0;}

    .switcher-container.sentence-switcher {  margin-right:0; flex-basis:100%;}
    .switcher-container.sentence-switcher .switcher {  width:calc(100% - 12px) }
        .switcher-container.sentence-switcher .switcher .option { justify-content:left; padding:0 16px; }


    .sentence-and-checkbox-container { width:484px; min-height:210px; transition-property:all; transition-duration:0.5s; float:left; display:flex; justify-content:space-between; flex-direction:column;}
    .checkbox { font-size:18px; cursor:pointer; padding-top:24px;}
        .checkbox .box { width:32px; height:32px; border-radius:8px; float:left;}
            .checkbox .box .icon { transition-property:opacity; transition-duration:0.2s; opacity:0; width:100%; height:100%; /*background:url(img/icon-check.svg) no-repeat center;*/}
            .checkbox .box .icon svg { margin:2px; filter:drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.45)); }
            .checkbox.active .box .icon {opacity:1; }
        .checkbox .label { float:left; margin:8px 0 0 8px;}


#sentence-border
{
    max-width:calc(860px - 8px);
    margin:32px 0;

    padding:4px;
    
    border-radius:16px;

    

    width:100%;
    
}
#sentence-container { 

    padding:24px 48px 56px;

    background-color:white;
    border-radius:12px;
    
    font-family: 'Grandstander';
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 56px;
    text-align: center;
    }

#sentence-container .label { display:block; font-weight:normal; font-size:16px; line-height:24px; margin:16px 0 16px; color:rgba(0,0,0,0.55)}

#filters-and-sentence { width:860px; display: flex; flex-direction:column; justify-content:center; align-items:center; align-content:stretch;}

footer { align-self:center; margin-bottom:40px; color:rgba(0, 0, 0, 0.55)}
    footer a { color:rgba(0, 0, 0, 0.55)}
    footer a:hover { color:orange}




@media screen and (max-width: 860px) {

    #top-content { padding:0 20px; }

    #logo { font-size: 18px;}

    h1, h2 { }
    h1 { margin:24px auto 8px; font-size:20px; line-height:32px; width:auto}
    h2 { margin:0 auto; line-height:24px; width:75%; font-size:13px; line-height:16px;}
    #filters-and-sentence { width:100%}
    #sentence-border { width:calc(100% - 8px)}
    #top-content { min-width: auto;}

    #filters {flex-wrap: wrap;}

    #filters-and-sentence { flex-direction: column-reverse;}

    
    #sentence-container { font-size:20px; line-height:32px; padding:12px 16px 24px}
    .switcher-container .label,
    #sentence-container .label { font-size:13px;}
    #sentence-container .label { margin:8px 0; }

    #small-filters-container { width:100%; margin:0 0 20px 0;}
    #small-filters-container .switcher-container { width:calc(50% - 8px); margin-right:16px;}
    #small-filters-container .switcher-container:last-child {margin-right:0;}
    #small-filters-container .switcher { width:auto;}


    .switcher .option { font-size:13px; height:40px; text-align:left; line-height:16px;}
    .switcher .plashka { height:40px; margin-bottom:-40px;}

    #sentence-border { margin:20px 0;}

    footer { margin:32px 0; font-size:13px;}
    
}